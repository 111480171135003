import { decodeJwt } from 'jose';

export const getCurrentGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
        return "Good Morning!";
    } else if (currentHour < 18) {
        return "Good Afternoon!";
    } else {
        return "Good Evening!";
    }
};

export const printUpdatedInvoice = (data) => {
    const invoiceData = data.invoice;
    const itemsData = data.invoiceItems;
    const printWindow = window.open("", "_blank");
  
    printWindow.document.write(`
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Invoice</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            font-size: 14px;
            line-height: 1.5;
          }
          
          .invoice-box {
            max-width: 800px;
            margin: 10px auto;
            padding: 20px;
            border: 1px solid #ddd;
            border-radius: 10px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          
          .invoice-header {
            text-align: center;
            margin-bottom: 20px;
          }
          
          .invoice-header img {
            max-width: 100px;
            margin-bottom: 10px;
          }
          
          .invoice-header h1 {
            margin: 0;
          }
          
          .invoice-header p {
            margin: 0;
            font-size: 12px;
          }
  
          .flex-container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-bottom: 20px;
            flex-wrap: nowrap;
            align-content: flex-start;
          }
  
          .flex-container .invoice-info,
          .flex-container .customer-info {
            width: 45%;
          }
  
          .invoice-info p, 
          .customer-info p {
            margin: 5px 0;
          }
  
          .products-section {
            margin-bottom: 20px;
          }
  
          .products-table {
            width: 100%;
            border-collapse: collapse;
            margin-bottom: 20px;
          }
  
          .products-table th, .products-table td {
            border: 1px solid #ddd;
            padding: 8px;
          }
  
          .products-table th {
            background-color: #f2f2f2;
            text-align: left;
          }
  
          .products-table td {
            text-align: right;
          }
  
          .products-table td:first-child, 
          .products-table th:first-child {
            text-align: left;
          }
  
          .footer {
            text-align: center;
            margin-top: 30px;
            font-size: 12px;
            color: #666;
          }
        </style>
      </head>
      <body>
        <div class="invoice-box">
          <div class="invoice-header">
            <h1>India Mobile</h1>
            <p>Shop No: 26, Dev Residency, Vandemataram CrossWind</p>
            <p>Gota, Ahmedabad, Gujarat - 382481</p>
            <p>Phone: 756 784 9558 | Email: indiamobile558@gmail.com</p>
          </div>
          
          <div class="flex-container">
            <div class="customer-info">
              <h2>Bill To:</h2>
              <p>Customer: ${invoiceData?.customer?.customerName}</p>
              <p>Mobile: ${invoiceData?.customer?.mobile}</p>
              <p>Email: ${invoiceData?.customer?.email}</p>
              <p>Address: ${invoiceData?.customer?.address}</p>
            </div>
  
            <div class="invoice-info">
              <h2>Invoice</h2>
              <p>Invoice #: ${invoiceData?.invoiceId}</p>
              <p>Date: ${new Date(invoiceData?.invoiceDate).toLocaleDateString()}</p>
              <p>Status: ${invoiceData?.invoiceStatus?.toLowerCase()}</p>
              <p>Payment Method: ${invoiceData?.paymentType}</p>
            </div>
          </div>
  
          <div class="products-section">
            <h2>Items</h2>
            <table class="products-table">
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Unit Price</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                ${itemsData?.map(item => `
                  <tr>
                    <td>${item?.products?.productName} ${item?.repairingDetails ? `- ${item.repairingDetails}` : ''}</td>
                    <td>${item?.quantity}</td>
                    <td>&#8377; ${item?.amount.toFixed(2)}</td>
                    <td>&#8377; ${(item?.quantity * item?.amount).toFixed(2)}</td>
                  </tr>
                `).join('')}
                <tr>
                  <td colspan="3">Discount</td>
                  <td>&#8377; ${invoiceData?.discount?.toFixed(2)}</td>
                </tr>
                <tr>
                  <td colspan="3"><strong>Total Amount</strong></td>
                  <td><strong>&#8377; ${invoiceData?.totalAmount?.toFixed(2)}</strong></td>
                </tr>
                <tr>
                    <td >Note:</td>
                    <td colspan="3" style="text-align:left">${invoiceData?.note || ''}</td>
                </tr>
              </tbody>
            </table>
          </div>
  
          <div class="footer">
            This is a computer-generated invoice; no signature is required.
          </div>
        </div>
      </body>
      </html>
    `);
  
    printWindow.document.close();
    printWindow.print();
};
  
export const printNewInvoice = (data) => {
    const printWindow = window.open("", "_blank");

    printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Invoice</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    font-size: 14px;
                    line-height: 1.5;
                }
                
                .invoice-box {
                    max-width: 800px;
                    margin: 10px auto;
                    padding: 20px;
                    border: 1px solid #ddd;
                    border-radius: 10px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }
                
                .invoice-header {
                    text-align: center;
                    margin-bottom: 20px;
                }
                
                .invoice-header img {
                    max-width: 100px;
                    margin-bottom: 10px;
                }
                
                .invoice-header h1 {
                    margin: 0;
                }
                
                .invoice-header p {
                    margin: 0;
                    font-size: 12px;
                }
    
                .flex-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    margin-bottom: 20px;
                    flex-wrap: nowrap;
                    align-content: flex-start;
                }
    
                .flex-container .invoice-info,
                .flex-container .customer-info {
                    width: 45%;
                }
    
                .invoice-info p, 
                .customer-info p {
                    margin: 5px 0;
                }
    
                .products-section {
                    margin-bottom: 20px;
                }
    
                .products-table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 20px;
                }
    
                .products-table th, .products-table td {
                    border: 1px solid #ddd;
                    padding: 8px;
                }
    
                .products-table th {
                    background-color: #f2f2f2;
                    text-align: left;
                }
    
                .products-table td {
                    text-align: right;
                }
    
                .products-table td:first-child, 
                .products-table th:first-child {
                    text-align: left;
                }
    
                .footer {
                    text-align: center;
                    margin-top: 30px;
                    font-size: 12px;
                    color: #666;
                }
            </style>
        </head>
        <body>
            <div class="invoice-box">
                <div class="invoice-header">
                    <h1>India Mobile</h1>
                    <p>Shop No: 26, Dev Residency, Vandemataram CrossWind</p>
                    <p>Gota, Ahmedabad, Gujarat - 382481</p>
                    <p>Phone: 756 784 9558 | Email: indiamobile558@gmail.com</p>
                </div>
                
                <div class="flex-container">
                    <div class="customer-info">
                        <h2>Bill To:</h2>
                        <p>Customer: ${data?.customerDetails?.customerName}</p>
                        <p>Mobile: ${data?.customerDetails?.mobile}</p>
                        <p>Email: ${data?.customerDetails?.email}</p>
                        <p>Address: ${data?.customerDetails?.address}</p>
                    </div>

                    <div class="invoice-info">
                        <h2>Invoice</h2>
                        <p>Invoice #: ${data.invoiceId}</p>
                        <p>Date: ${new Date(data.invoiceDate).toLocaleDateString()}</p>
                        <p>Status: ${data.invoiceStatus}</p>
                        <p>Payment Method: ${data?.paymentType}</p>
                    </div>
                </div>

                <div class="products-section">
                    <h2>Items</h2>
                    <table class="products-table">
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Qty.</th>
                                <th>Unit Price</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${data?.products?.map(item => `
                                <tr>
                                    <td>${item?.productName} ${item?.repairingDetails ? `- ${item.repairingDetails}` : ''}</td>
                                    <td>${item?.quantity}</td>
                                    <td>&#8377; ${Number(item?.amount)?.toFixed(2)}</td>
                                    <td>&#8377; ${(item?.quantity * item?.amount).toFixed(2)}</td>
                                </tr>
                            `).join('')}
                             <tr>
                                <td colspan="3">Discount</td>
                                <td>&#8377; ${data?.discount?.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td colspan="3"><strong>Total Amount</strong></td>
                                <td><strong>&#8377; ${data?.totalAmount?.toFixed(2)}</strong></td>
                            </tr>
                            <tr>
                                <td >Note:</td>
                                <td colspan="3" style="text-align:left">${data?.note || ''}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="footer">
                    This is a computer-generated invoice; no signature is required.
                </div>
            </div>
        </body>
        </html>
    `);

    printWindow.document.close();
    printWindow.print();
}

export const printSupplierTable = (data) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Suppliers</title>
            <style>
                body {
                    margin: 20px;
                    font-family: Arial, sans-serif;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                th, td {
                    border: 1px solid #ddd;
                    padding: 8px;
                    text-align: left;
                }
                th {
                    background-color: #f4f4f4;
                }
                td {
                    min-width: 150px;
                }
            </style>
        </head>
        <body>
            <h1>Suppliers</h1>
            <table>
                <thead>
                    <tr>
                        <th>Supplier Name</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Address</th>
                    </tr>
                </thead>
                <tbody>
                    ${data.map(supplier => `
                        <tr>
                            <td>${supplier.supplierName}</td>
                            <td>${supplier.contact}</td>
                            <td>${supplier.email}</td>
                            <td>${supplier.address}</td>
                        </tr>
                    `).join('')}
                </tbody>
            </table>
        </body>
        </html>
    `);
    printWindow.document.close();
    printWindow.print();
}

export const printCustomersTable = (data) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Customers</title>
            <style>
                body {
                    margin: 20px;
                    font-family: Arial, sans-serif;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                th, td {
                    border: 1px solid #ddd;
                    padding: 8px;
                    text-align: left;
                }
                th {
                    background-color: #f4f4f4;
                }
                td {
                    min-width: 150px;
                }
            </style>
        </head>
        <body>
            <h1>Customers</h1>
            <table>
                <thead>
                    <tr>
                        <th>Customer Name</th>
                        <th>Contact</th>
                        <th>Email</th>
                        <th>Address</th>
                    </tr>
                </thead>
                <tbody>
                    ${data.map(customer => `
                        <tr>
                            <td>${customer.customerName}</td>
                            <td>${customer.mobile}</td>
                            <td>${customer.email}</td>
                            <td>${customer.address}</td>
                        </tr>
                    `).join('')}
                </tbody>
            </table>
        </body>
        </html>
    `);
    printWindow.document.close();
    printWindow.print();
}

export const printProductsTable = (data) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Products</title>
            <style>
                body {
                    margin: 20px;
                    font-family: Arial, sans-serif;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                th, td {
                    border: 1px solid #ddd;
                    padding: 8px;
                    text-align: left;
                }
                th {
                    background-color: #f4f4f4;
                }
                td {
                    min-width: 150px;
                }
            </style>
        </head>
        <body>
            <h1>Products</h1>
            <table>
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Cost Price (&#8377;)</th>
                        <th>Selling Price (&#8377;)</th>
                    </tr>
                </thead>
                <tbody>
                    ${data.map(product => `
                        <tr>
                            <td>${product.productName}</td>
                            <td>${product.costPrice}</td>
                            <td>${product.sellingPrice}</td>
                        </tr>
                    `).join('')}
                </tbody>
            </table>
        </body>
        </html>
    `);
    printWindow.document.close();
    printWindow.print();
}

export const printCategoriesTable = (data) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Categories</title>
            <style>
                body {
                    margin: 20px;
                    font-family: Arial, sans-serif;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                th, td {
                    border: 1px solid #ddd;
                    padding: 8px;
                    text-align: left;
                }
                th {
                    background-color: #f4f4f4;
                }
                td {
                    min-width: 150px;
                }
            </style>
        </head>
        <body>
            <h1>Categories</h1>
            <table>
                <thead>
                    <tr>
                        <th>Category Name</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    ${data.map(category => `
                        <tr>
                            <td>${category.categoryName}</td>
                            <td>${category.description}</td>
                        </tr>
                    `).join('')}
                </tbody>
            </table>
        </body>
        </html>
    `);
    printWindow.document.close();
    printWindow.print();
}

export const printInvoicesTable = (data) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>Invoices</title>
            <style>
                body {
                    margin: 20px;
                    font-family: Arial, sans-serif;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                }
                th, td {
                    border: 1px solid #ddd;
                    padding: 8px;
                    text-align: left;
                }
                th {
                    background-color: #f4f4f4;
                }
                td {
                    min-width: 150px;
                }
            </style>
        </head>
        <body>
            <h1>Invoices</h1>
            <table>
                <thead>
                    <tr>
                        <th>Invoice #</th>
                        <th>Customer Name</th>
                        <th>Status</th>
                        <th>Payment Type</th>
                        <th>Invoice Amount (&#8377;)</th>
                        <th>Discount (&#8377;)</th>
                        <th>Total Amount (&#8377;)</th>
                    </tr>
                </thead>
                <tbody>
                    ${data.map(invoice => `
                        <tr>
                            <td>${invoice.invoiceId}</td>
                            <td>${invoice.customer.customerName}</td>
                            <td>${invoice.invoiceStatus}</td>
                            <td>${invoice.paymentType}</td>
                            <td>${invoice.totalAmount}</td>
                            <td>${invoice.discount}</td>
                            <td>${invoice.discount + invoice.totalAmount}</td>
                        </tr>
                    `).join('')}
                </tbody>
            </table>
        </body>
        </html>
    `);
    printWindow.document.close();
    printWindow.print();
}

export const printBalanceSheet = (incomeData, expenseData, totalIncomeAmount, totalExpenseAmount) => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
        <!DOCTYPE html>
        <html>
        <head>
            <meta charset="utf-8" />
            <title>Balance Sheet</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    margin: 20px;
                    color: #333;
                }
                h2 {
                    text-align: center;
                    margin-bottom: 20px;
                    font-size: 24px;
                    color: #007bff;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 20px;
                }
                table, th, td {
                    border: 1px solid #ccc;
                }
                th, td {
                    padding: 12px 16px;
                    text-align: left;
                    vertical-align: top;
                }
                th {
                    background-color: #007bff;
                    color: #fff;
                    font-size: 16px;
                }
                ul {
                    list-style-type: none;
                    padding-left: 0;
                }
                ul li {
                    margin-bottom: 10px;
                }
                ul li div {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                }
                ul li div .details {
                    display: flex;
                    flex-direction: column;
                }
                small {
                    color: #666;
                    font-size: 12px;
                    margin-top: 4px;
                }
                .total-row {
                    font-weight: bold;
                    background-color: #f8f9fc;
                }
                .total-row td {
                    padding: 15px;
                }
                .total-row span {
                    font-size: 16px;
                }
            </style>
        </head>
        <body>
            <h2>Balance Sheet Report</h2>
            <table>
                <thead>
                    <tr>
                        <th>Income</th>
                        <th>Expense</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style="padding: 10px 20px;">
                            <ul>
                                ${incomeData
                                    .map(
                                        (income) => `
                                        <li>
                                            <div>
                                                <div class="details">
                                                    <div>Invoice # ${income.invoiceId}</div>
                                                    <small>Bill to: ${income?.customer?.customerName}, ${income.invoiceStatus}, ${income.paymentType}</small>
                                                </div>
                                                <div>&#8377; ${income.totalAmount}</div>
                                            </div>
                                        </li>`
                                    )
                                    .join("")}
                            </ul>
                        </td>

                        <td style="padding: 10px 20px;">
                            <ul>
                                ${expenseData
                                    .map(
                                        (expense) => `
                                        <li>
                                            <div>
                                                <div class="details">
                                                    <div>${expense.title}</div>
                                                    <small>${expense.description}</small>
                                                </div>
                                                <div>&#8377; ${expense.amount}</div>
                                            </div>
                                        </li>`
                                    )
                                    .join("")}
                            </ul>
                        </td>
                    </tr>
                    <tr class="total-row">
                        <td>
                            <div style="display: flex; justify-content: space-between;">
                                <span>Total Income:</span>
                                <span>&#8377; ${totalIncomeAmount}</span>
                            </div>
                        </td>
                        <td>
                            <div style="display: flex; justify-content: space-between;">
                                <span>Total Expense:</span>
                                <span>&#8377; ${totalExpenseAmount}</span>
                            </div>
                        </td>
                    </tr>
                    <tr class="total-row">
                        <td colspan="2">
                            <div style="display: flex; justify-content: space-between;">
                                <span>Total Balance:</span>
                                <span>&#8377; ${Number(totalIncomeAmount - totalExpenseAmount)}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </body>
        </html>
    `);
    printWindow.document.close();
    printWindow.print();
};

export const getUserInfo = () => {
    const token = localStorage.getItem('token');
    if (token !== 'undefined' || token !== null || token !== undefined) {
        try {
            const decodedToken = decodeJwt(token);
            return decodedToken;
        } catch (error) {
            console.error('Invalid token:', error);
        }
    }
}