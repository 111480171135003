import React, { useEffect, useState } from 'react';
import { FaBars, FaUser, FaSearch } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../redux/authSlice';
import { getUserInfo } from '../../../utils';
import './Header.scss'; // Custom styles for the header

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({
    branch: 'SparkWeb Technologies'
  });

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  }

  useEffect(() => {
    setLoginDetails(getUserInfo());
  }, []);

  return (
    <>
      <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
        <a className="navbar-brand ps-3" href="/">{loginDetails?.branchName}</a>
        <button className="btn btn-link btn-sm order-1 order-lg-0 me-4" id="sidebarToggle">
          <FaBars />
        </button>

        <form className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">
          <div className="input-group">
            <input className="form-control search-input" type="text" placeholder="Search for..." aria-label="Search" aria-describedby="btnNavbarSearch" />
            <button className="btn btn-primary search-btn" id="btnNavbarSearch" type="button">
              <FaSearch />
            </button>
          </div>
        </form>

        <ul className="navbar-nav ms-auto">
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="navbarDropdown" href="#!" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <FaUser />
            </a>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
              <li>
                <a className="dropdown-item" href="#!" onClick={() => navigate('/change-password')}>Change Password</a>
              </li>
              <li><hr className="dropdown-divider" /></li>
              <li>
                <a className="dropdown-item" href="#!" onClick={handleLogout}>Logout</a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Header;
