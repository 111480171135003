import React, { useEffect, useState } from 'react';
import { FiHome, FiUsers, FiBox, FiPackage, FiTruck, FiFileText, FiPieChart } from 'react-icons/fi';
import { TbCategory } from "react-icons/tb";
import { GrMoney } from "react-icons/gr";
import { Link, useLocation } from 'react-router-dom';
import { getUserInfo } from '../../../utils';
import './Sidebar.scss'; // Assuming you place the custom styles here

const menuItems = [
    { icon: FiHome, text: 'Dashboard', path: '/' },
    { icon: FiUsers, text: 'Customers', path: '/customers' },
    { icon: FiBox, text: 'Products', path: '/products' },
    { icon: TbCategory, text: 'Category', path: '/category' },
    { icon: FiTruck, text: 'Suppliers', path: '/suppliers' },
    { icon: FiPackage, text: 'Stock', path: '/stock' },
    { icon: GrMoney, text: 'Expense', path: '/expense' },
    { icon: FiFileText, text: 'Invoices', path: '/invoice' },
    { icon: FiPieChart, text: 'Reports', path: '/reports' },
];

function Sidebar({ isCollapsed }) {
    const location = useLocation();
    const [loginDetails, setLoginDetails] = useState({
        branch: 'SparkWeb Technologies',
        username: 'John'
    });

    useEffect(() => {
        setLoginDetails(getUserInfo());
    }, []);

    return (
        <div id="layoutSidenav" className={isCollapsed ? 'collapsed' : ''}>
            <div id="layoutSidenav_nav">
                <nav className={`sidebar accordion ${isCollapsed ? 'collapsed' : ''}`} id="sidenavAccordion">
                    <div className="sidebar-menu">
                        <div className={`sidebar-heading ${isCollapsed ? 'collapsed-heading' : ''}`}>
                            MENU
                        </div>
                        {menuItems.map((menu) => (
                            <Link
                                to={menu.path}
                                key={menu.path}
                                className={`sidebar-link ${location.pathname === menu.path ? 'active' : ''} ${isCollapsed ? 'collapsed-link' : ''}`}
                            >
                                <div className="sidebar-icon">
                                    <menu.icon className="icon" />
                                </div>
                                {!isCollapsed && <span className="sidebar-text">{menu.text}</span>}
                            </Link>
                        ))}
                    </div>
                    <div className="sidebar-footer">
                        <div className="small">Logged in as:</div>
                        <div className="sidebar-username">{loginDetails?.username}</div>
                        <div className="sidebar-branch">{loginDetails?.branch}</div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default Sidebar;
