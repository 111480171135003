import React, { Suspense, lazy  } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./components/common/Layout/Layout";
import LoadingSpinner from "./components/common/Loading/LoadingSpinner";
import './App.scss';

// Lazy-loaded components
const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const CustomerList = lazy(() => import("./components/customers/CustomersList"));
const CustomerForm = lazy(() => import("./components/customers/CustomersForm"));
const ProductsList = lazy(() => import("./components/products/ProductsList"));
const ProductsForm = lazy(() => import("./components/products/ProductsForm"));
const CategoryList = lazy(() => import("./components/category/CategoryList"));
const CategoryForm = lazy(() => import("./components/category/CategoryForm"));
const SuppliersList = lazy(() => import("./components/supplier/SuppliersList"));
const SuppliersForm = lazy(() => import("./components/supplier/SuppliersForm"));
const StockList = lazy(() => import("./components/stock/StockList"));
const StockForm = lazy(() => import("./components/stock/StockForm"));
const InvoiceForm = lazy(() => import("./components/invoice/InvoiceForm"));
const InvoiceList = lazy(() => import("./components/invoice/InvoiceList"));
const LoginForm = lazy(() => import("./components/auth/login"));
const ChangePasswordForm = lazy(() => import("./components/auth/changePassword"));
const Reports = lazy(() => import("./components/reports/reports"));
const ExpenseList = lazy(() => import("./components/expense/ExpenseList"));
const ExpenseForm = lazy(() => import("./components/expense/ExpenseForm"));
const NotFoundPage = lazy(() => import("./components/common/Error/NotFoundPage"));

function App() {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return (
    <Router>
      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/login" element={<LoginForm />} />
          <Route
            path="/*"
            element={
              isAuthenticated ? (
                <Layout>
                  <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/customers" element={<CustomerList />} />
                    <Route path="/customers/create" element={<CustomerForm />} />
                    <Route path="/customers/edit/:id" element={<CustomerForm />} />
                    <Route path="/products" element={<ProductsList />} />
                    <Route path="/products/create" element={<ProductsForm />} />
                    <Route path="/products/edit/:id" element={<ProductsForm />} />
                    <Route path="/category" element={<CategoryList />} />
                    <Route path="/category/create" element={<CategoryForm />} />
                    <Route path="/category/edit/:id" element={<CategoryForm />} />
                    <Route path="/suppliers" element={<SuppliersList />} />
                    <Route path="/suppliers/create" element={<SuppliersForm />} />
                    <Route path="/suppliers/edit/:id" element={<SuppliersForm />} />
                    <Route path="/stock" element={<StockList />} />
                    <Route path="/stock/create" element={<StockForm />} />
                    <Route path="/stock/edit/:id" element={<StockForm />} />
                    <Route path="/expense" element={<ExpenseList />} />
                    <Route path="/expense/create" element={<ExpenseForm />} />
                    <Route path="/invoice" element={<InvoiceList />} />
                    <Route path="/invoice/create" element={<InvoiceForm />} />
                    <Route path="/invoice/edit/:id" element={<InvoiceForm />} />
                    <Route path="/change-password" element={<ChangePasswordForm />} />
                    <Route path="/reports" element={<Reports />} />
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </Layout>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
